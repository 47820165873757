@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.bookingContainer {
  padding-left: $spacing-6;
  padding-right: $spacing-6;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.datePickerContainer {
  padding-top: $spacing-6;
  display: flex;
  flex-direction: column;

  div:nth-child(1) {
    margin-bottom: $spacing-3;
  }

  :global(.react-datepicker__day),
  :global(.react-datepicker__day-name) {
    width: 25px !important;
    line-height: 28px !important;
  }
}
