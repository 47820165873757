@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.breakContainer {
  margin: $spacing-3 auto;
  border-bottom: 1px solid #dedede;
  color: $blue-dark-v100;
  font-size: 14px;
  line-height: $spacing-6;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.dates {
  margin-top: 6px;
}

.numOfNights {
  font-size: 12px;
  font-weight: $fw-bold;
  color: #575757;
  margin-bottom: 14px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 -$spacing-6;
  padding: $spacing-3 16px;
  background-color: #ffffff;
  border-top: 1px solid #e7e7e7;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  @media screen and (max-width: 959px) {
    position: fixed;
    margin: 0;
  }
}

.cancelButton {
  width: 76px !important;
  color: #e0004d !important;
  border: 1px solid #e0004d !important;
}

.bookButton {
  width: 180px !important;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookCheckbox {
  width: 20px;
  height: 20px;
  border: 1px solid #007cc2;
  background-color: white;
  border-radius: $spacing-1;
  cursor: pointer;
  margin-right: 16px;
}

.checked {
  background-color: #007cc2;
}

.disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

.waitlistAdInfo {
  padding: 0 $spacing-6;
  color: $blue-dark-v100;
  font-style: 16px;
  line-height: 28px;
  font-weight: $fw-normal;
}

.waitlistAdQuestion {
  font-weight: $fw-bold;
  margin: 16px 0;
}

.waitlistAdButton {
  margin-bottom: 16px !important;
  font-size: 14px !important;
  width: 270px !important;
}
