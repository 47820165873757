@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.keyPanel {
  overflow-y: auto;

  @media screen and (max-width: 958px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
  }

  .keyPanelContent {
    padding: $spacing-6;

    .gutterBottom {
      margin-bottom: 20px;
      margin-top: 8px;
    }

    .today {
      color: #007cc2;
    }

    .peakContainer {
      margin-bottom: 8px;
      width: 62px;
      height: 72px;
      background-color: #f0f9fc;
      padding: $spacing-1 8px;

      .peakValue {
        height: 100%;
        padding: $spacing-1;
        border-left: 1px solid #dedede;
        border-right: 1px solid #dedede;
      }

      &.superPeak {
        margin-top: 20px;

        .peakValue {
          background: linear-gradient(
            to bottom,
            #dbf0f9 0%,
            #dbf0f9 40%,
            #f0f9fc 40%,
            #f0f9fc 100%
          );
        }
      }
    }
  }
}
