@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.renewalStepper {
  margin: 0 auto 35px;

  @media screen and (min-width: $layout-breakpoint) {
    max-width: 500px;
  }
}

.loading {
  display: flex;
  justify-content: center;

  & + * {
    margin-top: 56px;
  }
}
