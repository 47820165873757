@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.maxBreakList {
  span {
    font-weight: $fw-bold;
    display: block;
    margin-bottom: $spacing-1;
  }

  color: $blue-dark-v100;
  margin: $spacing-1 0 20px;
}
