@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.lgContainer {
  min-height: 100vh;
  position: relative;
  max-width: 1280px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin: 0px auto;
}
.actionRequiredContainer {
  height: 48px;
  background-color: #e0004d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $fw-bold;
  color: #ffffff;
  z-index: 3;
  position: sticky;
  top: 74px;
  font-size: 12px;
  img {
    margin-right: 8px;
  }
  @media screen and (max-width: 959.95px) {
    top: 50px;
  }
}
.calendarButton {
  font-size: 14px !important;
  margin-left: 8px !important;
}
