@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.editorWrapper {
  background-color: #f9f9f9;
  margin: 0px;
  width: 100%;
}
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
}
.tab {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  border-left: 3px solid #e7e7e7;
  border-bottom: 3px solid #e7e7e7;
  &:first-of-type {
    border-left: none;
  }
  &.active {
    border-bottom-color: #031545;
  }
}
