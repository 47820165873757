@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.accountSelector {
  margin: auto 0px $spacing-6 auto;
  width: 220px;
}

.bookingsPositioning {
  position: absolute;
  right: 0;
}
