@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.link {
  color: #01639b;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-top: $spacing-3;
  margin-bottom: $spacing-3;
  text-decoration: none;
  &::before {
    top: calc(50% - 6px);
    left: 0;
    width: $spacing-3;
    height: $spacing-3;
    content: '';
    display: inline-block;
    position: absolute;
    transform: rotate(180deg);
    background: url('https://www.haven.com/assets/icons/arrow-ios-right-blue.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
