@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.calendarPanel {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
  height: 580px;
  margin-left: 14px;
  border-radius: $border-radius-md;

  @media screen and (max-width: 960px) {
    width: 100vw;
    max-width: 100vw;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    border-radius: 0;
    margin: 0;
    height: auto;
  }
}
