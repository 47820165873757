@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.sliderContainer {
  max-width: 720px;
  width: 100%;
  border-radius: $border-radius-md;
  border: 1px solid #031545;
  margin-bottom: $spacing-3;

  &.fullWidth {
    max-width: 100%;
  }

  .topContainer {
    background-color: #031545;
    color: white;
    padding: 20px $spacing-6;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: $spacing-3;
      font-weight: $fw-bolder;
    }

    .description {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: $spacing-6;
    }

    .actionWrapper {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $md) {
        justify-content: space-between;
        margin-bottom: $spacing-6;
        flex-direction: row;
      }

      .lettingPlanRadio {
        max-width: 200px;
        width: 100%;
        margin-bottom: $spacing-6;
        margin-left: 2px;

        .lettingPlanSingle {
          font-weight: $fw-normal;
          font-size: 16px;
          margin-top: 8px;
        }

        &:hover * {
          background-color: #031545 !important;
        }

        & > p {
          font-size: 14px;
          margin: 0;
        }

        span {
          input {
            & + span {
              width: 20px !important;
              height: 20px !important;
            }
          }
        }

        @media screen and (min-width: $md) {
          margin-bottom: 0;
        }
      }

      .offPeakCheckbox {
        &:hover * {
          background-color: #031545 !important;
        }

        margin-bottom: 16px;

        @media screen and (min-width: $md) {
          display: flex;
          align-self: flex-end;
          margin-bottom: 0;
          margin-left: 0;
          margin-top: 0;
        }
      }
    }

    .displayDivider {
      height: 1px;
      background: white;
      border: none;
    }

    .gradeDescription {
      color: #818aa2;
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: $spacing-6;

      & + .siteFeeContainer {
        margin-top: 60px;
      }
    }
  }

  .pointer {
    width: $spacing-8;
    height: $spacing-8;
    background-color: #031545;
    margin-top: -16px;
    transform: rotate(45deg);
    margin-left: calc(50% - 14px);
  }

  .bottomContainer {
    color: #031545;
    text-align: center;
    padding: $spacing-6 0;
    font-weight: $fw-bold;

    .earn {
      font-size: 14px;
      line-height: $spacing-6;
    }

    .priceRange {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: $spacing-3;
      font-weight: $fw-bolder;
    }

    button {
      font-family: 'Heinemann HVN', sans-serif;
    }
  }

  .siteFeeContainer {
    width: 93%;
    display: flex;
    margin: 0 auto;
    position: relative;

    .siteFeeDashed {
      width: fit-content;
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 5;
      top: -56px;
      padding-top: 20px;
      align-items: center;

      p {
        text-align: center;
        margin: 0;
        font-weight: $fw-normal;
        font-size: 12px;
        line-height: 20px;
      }

      hr {
        border: none;
        border-left: 2px dotted #fff;
        height: 40px;
        margin: 0 0 -35px 0;
        width: fit-content;
      }
    }

    & + .breakSlider {
      div {
        &:last-of-type {
          z-index: 5;
        }
      }
    }
  }
}
