@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.listWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .list {
    padding-top: $spacing-3;

    @media (max-width: $layout-breakpoint) {
      padding: $spacing-3 $spacing-3 0 $spacing-3;
    }

    section {
      margin-bottom: 36px;
      & > p {
        color: #031545;
        font-size: 16px;
        font-weight: $fw-bold;
        border-bottom: 1px solid #031545;
        padding-bottom: 8px;
      }
      span {
        color: #031545;
        font-size: 14px;
      }
    }
  }
}
