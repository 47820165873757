@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.mainContainer {
  padding-bottom: 70px;
}
.label {
  color: $blue-dark-v100;
  font-size: 18px;
  font-weight: $fw-bolder;
}
.editorContainer {
  display: flex;
  flex-direction: column;
  padding: $spacing-3;
  > * {
    margin-bottom: $spacing-3;
  }
}
