@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.icon {
  display: flex;
  margin-right: grid(5);
  border-radius: 50%;
  border: 1px solid $blue-primary-v100;
  width: 20px;
  height: 20px;
  transition: border-color 0.2s ease;
  cursor: pointer;

  &:before {
    content: '';
    margin: auto;
    display: block;
    width: $spacing-3;
    height: $spacing-3;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.2s ease;
  }

  &.disabled {
    border: 1px solid $achromatic-cloud;
  }
  &.selected {
    &:before {
      background-color: $blue-primary-v100;
    }
  }
}

.mainContainer {
  padding-bottom: 70px;
}
.formLabel {
  color: $blue-primary-v100;
  font-size: 14px;
  margin: 16px 0px 8px 0px;
}
.formControlWrapper {
  display: grid;
  margin-bottom: 14px;
  grid-template-columns: 20px 1fr;
  align-items: baseline;
  cursor: pointer;
  p {
    margin: 0px;
  }
}
.formText {
  margin-left: $spacing-3;

  p:nth-child(1) {
    font-size: 16px;
    font-weight: $fw-bold;
    color: $blue-dark-v100;
  }
  p:nth-child(2) {
    margin: 0px;
    font-size: 16px;
  }
}
.formBorder {
  display: flex;
  flex-direction: column;

  padding: $spacing-6;
  border: 1px solid $blue-primary-v100;
  border-radius: $border-radius-md;

  img {
    margin-left: auto;
  }
}

.comingSoonContainer {
  border: 1px solid #007cc2;
  border-radius: $border-radius-md;
  display: flex;
  flex-direction: column;
  padding: $spacing-6;
  margin-bottom: $spacing-3;
  > h2 {
    margin-bottom: 0px;
    margin-top: 6px;
  }
  > p {
    margin-top: 0px;
    margin-bottom: 6px;
  }
  > img {
    align-self: flex-end;
  }
}
.comingSoon {
  align-items: center;
  align-self: flex-end;
  color: #424f73;
  display: flex;
  font-size: 12px;
  font-weight: $fw-bold;
  margin-top: -$spacing-3;
  margin-right: -$spacing-3;
  > img {
    padding-left: 6px;
  }
}

.label {
  color: $blue-dark-v100;
  font-size: 18px;
  font-weight: $fw-bolder;
}
