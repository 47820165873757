@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.stepperContainer {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-3;
  margin-bottom: 0px !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding: $spacing-3 16px;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  @media screen and (max-width: $sm) {
    position: fixed;
  }
}
.dotsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot {
  width: 8px;
  height: 8px;
  background-color: #e7e7e7;
  border-radius: 50%;
  margin: $spacing-1;
}
.activeDot {
  background-color: #575757;
}
