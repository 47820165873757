@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.editorContainer {
  display: flex;
  flex-direction: column;
  padding: $spacing-3;
  background-color: #fff;
  > * {
    margin-top: $spacing-3 !important;
  }
}
