@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.dialogContent {
  padding: 56px $spacing-8 48px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $achromatic-white;
  max-width: 80%;
  border: 1px solid $achromatic-storm;
  border-radius: $border-radius-md;
  margin: 20px 0px;
}
.closeButton {
  position: absolute;
  top: 0;
  right: 0;
}
.title {
  color: $blue-dark-v100;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  font-weight: $fw-normal;
  margin: 8px 0px 28px;
}
.text {
  color: $blue-dark-v100;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  font-weight: $fw-normal;
  margin: 0px;
  margin-bottom: $spacing-6;
}
.bold {
  font-weight: $fw-bold;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: $spacing-6;
  flex-wrap: wrap;
}
