@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.InlineMessage {
  display: flex;
  padding: $spacing-3;
  margin: $spacing-3 0;
  border-radius: $spacing-1;
  font-size: 14px;
  font-weight: $fw-normal;

  &.error {
    background-color: #fdf2f6;
    border: 1px solid #e0004d;
  }

  &.info {
    background-color: #fffbf3;
    border: 1px solid #ffbb13;
  }

  .closeIcon {
    margin-left: auto;
    margin-right: 0px;
    cursor: pointer;

    img {
      width: 8px;
    }
  }
}
