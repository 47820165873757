@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.tabContainer {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.tabSection {
  width: 100%;
  margin: 0;
}

.firstSection {
  margin: 0;
  width: 100%;
  padding: $spacing-1 $spacing-3;
}

.dates {
  margin-top: 6px;
}

.numOfNights {
  font-size: 12px;
  font-weight: $fw-bold;
  color: #575757;
}

.previewTitle {
  margin-top: 20px;
  font-weight: $fw-bolder;
  color: #031545;
}

.previewDetails {
  width: 100%;
  padding: 0 $spacing-3;

  & > * {
    margin-bottom: 8px;
  }
}

.actionContainer {
  padding: $spacing-1 $spacing-3 $spacing-3 $spacing-3;
}

.loadingIndicator {
  width: 90%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
