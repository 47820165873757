@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.badge {
  height: 16px;
  border-radius: $border-radius-md;
  border: 1px solid;
  color: #575757;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: $fw-bold;
  text-align: left;
  padding: 0px 8px;
  width: fit-content;
  margin: 0px;
  border-color: #007cc2;
  background-color: #e5f1f8;
  &.arrivalBooking {
    color: white;
    background-color: #29822f;
    border-color: #29822f;
  }
  &.waitlist {
    color: $blue-dark-v100;
    border-style: dashed;
    background-color: #fffbf3;
    border-color: #ffbb13;
  }
  &.closed {
    border-color: #e0004d;
    background-color: #e0004d;
    color: white;
  }
  &.letWithHaven {
    color: $blue-dark-v100;
    background-color: #e5e7ec;
    border-color: $blue-dark-v100;
    border-style: dashed;
  }
  &.letWithHavenConfirmed {
    color: white;
    background-color: $blue-dark-v100;
    border-color: $blue-dark-v100;
  }
  &.maintenance {
    border-color: #424f73;
    background-color: #424f73;
    color: white;
  }
}
.dashedWrapper {
  display: flex;
  align-items: center;
  height: $spacing-3;
  width: 50%;
  border-left: 1px solid $blue-dark-v100;
  border-right: 1px solid $blue-dark-v100;
}
.dashedLine {
  width: 100%;
  height: 2px;
  border-top: 1px dashed $blue-dark-v100;
  border-radius: 999px;
}
