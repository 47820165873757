@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.container {
  margin: 16px 0;
}

.infoContainer {
  display: flex;
  flex-direction: column;
}

.infoCopy {
  margin-top: 16px;
}
