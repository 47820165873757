@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.container {
  background-color: $blue-dark-v10;
  border: 1px solid $blue-dark-v100;
  border-radius: $border-radius-sm;
  padding: $spacing-3;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: flex-start;
  color: $blue-dark-v100;
  min-width: 320px;
  @media (max-width: 1200px) {
    width: 100% !important;
    min-width: unset;
  }
}
.success {
  background-color: $brand-green-v25;
  border-color: $brand-green-v100;
}
.error {
  background-color: $brand-coral-v25;
  border-color: $brand-coral-v100;
}
.warning {
  background-color: $brand-orange-v25;
  border-color: $brand-orange-v100;
}
.icon {
  height: $spacing-6;
  width: $spacing-6;
  padding: 1px;
}
.content {
  padding-left: 8px;
  & p {
    font-size: 14px;
    line-height: $spacing-6;
  }
  & a {
    color: $blue-primary-v100;
    white-space: nowrap;
  }
}
