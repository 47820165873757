@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.siteFeeHolding {
  display: flex;
  flex-direction: column;
  max-width: 710px;
  h1 {
    font-family: 'Heinemann HVN', sans-serif;
    font-style: normal;
    font-weight: $fw-normal;
    font-size: 36px;
    line-height: 48px;
    color: $blue-dark-v100;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px $spacing-3;
    background-color: #f2f3f6;
    color: $blue-dark-v100;
    h2 {
      font-weight: $fw-normal;
      font-size: 24px;
      line-height: $spacing-8;
      margin-bottom: 20px;
    }
    p {
      max-width: 600px;
      font-weight: $fw-normal;
      text-align: center;
      font-size: 16px;
      margin: 0px;
    }
    p:nth-of-type(2) {
      margin-top: $spacing-3;
    }
    button {
      margin-top: 26px;
    }
  }
  .widget {
    margin-top: $spacing-6;
  }
}
