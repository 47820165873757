@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.gutterBottom {
  margin-bottom: $spacing-3;
}
.gutter-top {
  margin-top: $spacing-3;
}
.bannerContainer {
  margin: $spacing-6 0px;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  & a {
    text-decoration: none;
    color: inherit;
  }
  @media screen and (max-width: $sm) {
    & > div:nth-of-type(1) {
      order: 1;
    }
    & > div:nth-of-type(2) {
      order: 0;
    }
    & > div:nth-of-type(3) {
      order: 2;
    }
  }
}
.sectionHeaderLink {
  color: $blue-primary-v100 !important;
  font-size: 16px;
  line-height: 28px;
  text-decoration: underline !important;
  font-weight: $fw-normal;
  cursor: pointer;
}
.activitiesLoading {
  min-height: 210px;
  @media screen and (max-width: $sm) {
    min-height: 250px;
  }
}
.activitiesWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.newsLoading {
  min-height: $spacing-14;
}
.cardsContainer {
  display: grid;
  gap: $spacing-6;
  grid-template-columns: 1fr 1fr;
  margin-top: 28px;
  margin-bottom: $spacing-3;
  @media screen and (max-width: $sm) {
    grid-template-columns: 1fr;
  }
}
.badge {
  border: 1px solid;
  border-radius: $border-radius-md;
  color: #575757;
  font-size: 12px;
  font-weight: $fw-bold;
  letter-spacing: 0$spacing-1;
  line-height: 16px;
  padding: 0px $spacing-1;
  width: 180px;
  height: 20px;
  background-color: #fff8e7;
  border-color: #ffbb13;
  margin-bottom: 6px;
}
.tileHeader {
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  font-weight: $fw-bold;
  line-height: 28px;
  margin-top: -$spacing-1;
  margin-left: auto;
  font-size: 12px;
  & img {
    margin-left: 8px;
  }
}
.frostDue {
  color: #031545;
}
.lettingAction {
  color: #e0004d;
}
.lettingBanner {
  width: 100%;
  height: 152px;
  margin-bottom: $spacing-6;
  background-color: #fff8e7;
  border-radius: $border-radius-md;
  box-shadow: 0 2px $spacing-1 0 rgba(0, 0, 0, 0.1);
  padding: 16px $spacing-6;
  color: #031545;
  font-weight: $fw-normal;
  @media screen and (max-width: $md) {
    height: auto;
  }
}
.lettingBannerTitle {
  font-size: 24px;
  line-height: $spacing-8;
  margin-bottom: 8px;
}
.lettingBannerBody {
  display: flex;
  flex-direction: row;
  flex: 0 0;
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width: $sm) {
    flex-direction: column;
  }
}
.lettingBannerText {
  margin-right: $spacing-6;
  @media screen and (max-width: $sm) {
    margin-right: 0px;
    margin-bottom: $spacing-6;
  }
}
.lettingBannerButton {
  flex-shrink: 0;
  text-decoration: none;
}

.surveyWrapper {
  margin-bottom: 20px;
  @media screen and (max-width: $md) {
    margin-bottom: 40px;
  }
}
