@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
@import '~@havenengineering/module-shared-owners-ui/dist/styles/global.scss';

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  display: block !important;
  background: #fff !important;
  font-family: 'Heinemann HVN', sans-serif;
  margin: 0;
}
.page-title {
  color: #333333;
  font-size: 28px;
  font-weight: $fw-normal;
  margin-bottom: 20px;
  width: 100%;
  @media screen and (min-width: 600px) {
    width: calc(100% - 230px);
  }
}
.has-van-selector .page-title {
  @media screen and (min-width: 600px) {
    margin-top: -55px;
  }
}

.bold {
  font-weight: $fw-bold !important;
}

#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: #007cc2;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: $spacing-1;
}
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 $spacing-2 #007cc2, 0 0 $spacing-1 #007cc2;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -$spacing-1);
}
